@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

.header-and-content{
    background-image:  url('./img/contact-background.png');
    font-family: "Instrument Sans", sans-serif;
    background-position: center;
    
    background-repeat: no-repeat;
    background-attachment: fixed; /* This will keep the image fixed during scroll */
    background-size: cover; /* This will make sure that your background covers the entire element */
    min-height: 100%; /* Minimum height to ensure it covers from top to just above the footer */
    padding-bottom: 10%;
    
}

.question-text{
    color: #0b1a51;
}

.contact-button{
    color: white;
    background-color: #00ce7c;
}

.input-contact{
    font-size: 1.3vw;
    border-bottom: 2px solid #cfd9e3; /* Green bottom border, 2px thick */
    color: #002366;
}
.input-contact::placeholder {
    color: #002366;    
  }

.contact-question{
color: #0b1a51;
 margin-top: 15vw !important;
 margin-bottom: 5vw;
 background-color:rgba(255, 255, 255, 0.904);
 padding-bottom: 5%;
}
.separator{
    margin-left: 5px;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
    .connect-question-div{
        border-radius: 6vw;
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 10vw !important;
        flex-direction: column;
        align-content: center;
        text-align: center;
    }
    .connect-question-div h3{
        white-space: nowrap !important;
        display: inline-block !important;
        text-align: left;
        font-size: 9vw;
    }
    .connect-question-div p{
        padding-top: 2vw !important;
        font-size: 3.4vw;
    }
    .header-and-content{
        background-position: calc(100% + 70vw) calc(100% + -70vw ); /* Align the image to the right and 50% below the div */
        background-size: auto 90% !important; /* Double the height of the background image */
    }
    .para-margin{
        margin-bottom: 10vw !important;
    }
    .line-input{
    font-size: 3.4vw;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0 !important;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2vw;
    }
    .connect-question-form-div{
        margin-left: 0;
        padding-right: 0 !important;
        margin-right: 0 !important;
    }
    .connect-question-form-div{
        
        margin-right: 0;
    }

    .contact-question{
        margin-top: 45vw !important;
        padding-bottom: 15vw;
    }
    .connect-question-text-div{
        text-align: left;
    }
    .question-button{
        margin-left: 0;
        align-self: center;
        padding-bottom: 4vw !important;
        padding-top: 4vw !important;
        width: 80% ;
        font-size: 4vw !important;
        font-weight: 550 !important;
    
    }
    .nav-contact{
        background-color: white;
    }
    }

