/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15000 !important;
}

.modal-container {
  color: #002366;
  background-color: white;
  border-radius: 2.5vw;
  padding: 100px;
  padding-top: 30px;
}
.modal-header {
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc; /* Change the color as needed */

}
.modal-header h2{

  font-size: 3vw;
  align-self: flex-start;
}
.modal-header p{
  font-size: 1.3vw;
  margin-bottom: 2em;
  font-weight: 400;
  align-self: flex-start;
}
.modal-footer{
  padding-top: 4em;
  margin-top: 3em;
  border-top: 1px solid #ccc; /* Change the color as needed */
  white-space: pre-wrap;
  display: flex;
}
.modal-p{

  margin-right: 15px;
  margin-left: 15px;
line-height: 100%;
font-size: 1.3vw;
}
.modal-img{
margin-left: 1em;
height: 3vw;
}
.modal-body {
  overflow-y: auto;
}



.modal-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.padding{
  padding: 15px;
}
.modal-content::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 1px solid #ccc; /* Customize the color as needed */
  transform: translateX(-50%);
}

.municipality {
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  margin: 0px 20px;
}

.municipality-name {
  color: #00ce7c;
}
.letter-x{
  width: 60px;
}

.close-button {
  height: 50px;
  width: 50px;
  display: block;
  align-self: flex-end;
  font-size: 3vw;
  background: none;
  border: none;
  cursor: pointer;
  /* Add other styles for the button */
}

.close-button:after{
  border-radius: 50px;
  
}

/* Add other styles as per the design like colors, typography etc. */

/* Add this to your CSS file */
.header-container {
  /* Your existing styles for the header */
  border-bottom: 1px solid #ccc; /* Change the color as needed */
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .municipality, .modal-p, .modal-header p{
    font-size: 3.4vw;
  }
  .municipality{
    gap: 15vw !important;
  }
  .modal-container{
    margin-left: 2vw;
    margin-right: 2vw;
    padding: 0;
    
  }
  .modal-header{
    
    margin-top: -8vw !important;
    
    margin-left: 5vw;
    margin-right: 2vw;
  }
.modal-footer {
  display: flex;
  flex-direction: column;
  padding-top: 3vw;
  margin-top: 2em;
  margin-left: 3vw;
  margin-right: 2vw;
  margin-bottom: -10vw;
  align-items: flex-start; /* Ensures left alignment for mobile */
}

  .letter-x{
    transform: translateY(10vw);
    width: 40px;
  }

  
  .modal-img{
    margin: 0;
    padding: 0;
    width: 5vw;
    height: 5vw;
    margin-bottom: 1.5vw;
    margin-left: 2vw;
  }

  .modal-p{
    transform: translateY(-18vw);
    margin-bottom: 3.5vw;
    order: 1;
    text-align: left !important;
    white-space: nowrap;
    padding-left: 0 !important;
    margin-right: 0;
    text-wrap: wrap;
    margin-left: 9vw;
  }


  .modal-header h2{
    font-size: 6vw;
  }
}
