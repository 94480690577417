@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

.about-page{
    font-family: "Instrument Sans", sans-serif;
    color: #0b1a51;
    white-space: pre-wrap;
}

.about-main-div{  
  margin-top: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  padding-top: 20px;
  background-image: url('./img/about-background-tick.png'); /* Update the path to your image */
  background-repeat: no-repeat;
  background-position: calc(100% + 9vw) calc(100% + 19vw); /* Align the image to the right and 50% below the div */
  background-size: auto 129%; /* Double the height of the background image */
}

.about-text-div {
  margin-top: 4em;
    margin-left: 10%;
    flex: 1; /* Allows this div to grow and take up remaining space */
    padding-right: 30px; /* Add some space between the text and the picture */
  }
  .about-picture-div {
    
    margin-right: 10%;
    flex: 0 0 auto; /* Don't let the picture grow or shrink */
  }

  .about-picture-div img{

    width: 19vw;
  }
.about-main-div p{
    margin-left: 0.5em;
    line-height: 100%;
    font-weight: 400;  
    font-size: 1.3vw;    
}

.about-button{
  padding: 0.8vw 3vw; /* Button padding */
    font-size: 1.3vw;
    font-weight: 500;
    margin-left: 0px;
}

.about-main-div h1{
    line-height: 100%;
    font-size: 5vw;
    margin-bottom: 0.5em;
}

.important-div{
    margin-top: 6%;
    margin-bottom: 15%;
    width: 100%;
    align-self: center;
    align-items: center;
    color: #002366;
}

.green-title{
    color: #00ce7c; /* White background */
}

.important-div h2{
    padding-top: 1.5em;
    font-size: 3vw;
    margin-bottom: 1em;
    text-align: center;
}

.important-div p{
  font-weight: 450;
    line-height: 120%;
    font-size: 1.3vw;
    white-space: pre-wrap;
    text-align: center;
}

.about-green-paragraph{
    color: #00ce7c; /* White background */
    font-weight: 550;

}
.about-description-text{
    margin-left: auto; /* Pushes the element to the right */
    padding: 4em;
    padding-bottom: 3em;
    padding-left: 4.5em;
    padding-right: 4.5em;
    backdrop-filter: blur(10px); /* Adjust blur radius as needed */
    line-height: 120%;
    margin-right: 12%;
    margin-left: 12%;
    align-self: flex-end;
}


.about-description-div {
    flex-wrap: wrap;
    padding-top: 5%;
    display: flex;
    flex-direction: column; /* Default value, but included for clarity */
    background-position: center top;
    background-size: 100% auto;
    align-items: flex-start; /* Aligns children to the start of the cross axis, in this case, the top */
    background-image:  url('./img/about-background.png');
    background-size: cover !important;
    background-repeat: no-repeat;

}
.about-description-instruction{
    margin-bottom: 1em;
    margin-top: 2em;
    margin-left: 12%;    
}
.about-description-instruction h2{
    line-height: 95%;
    font-size: 2.5vw;
    font-weight: 500;
}
.about-description-text {
    font-weight: 400;
    font-size: 1.3vw;
    background-color:rgba(255, 255, 255, 0.411);
    border-radius: 2.5vw;
    margin-top: 30px;
}
.about-wrapper {
    padding-top: 2em;
    padding-left: 11%;
    padding-right: 11%;
    padding-bottom: 5em;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .about-box{
    text-align: left;
    margin-left: 2vw;
  }
  .about-box h5{
    font-size: 1.3vw;
}
  .about-box p{
        line-height: 100%;
        
        font-size: 1vw;
  }
  .sez {
    align-items: center;
    border-radius: 2vw;
    display: flex;
    height: 10vw;
    width: 31.5%;
    padding-left: 2vw;
    background-color: white;
  }
  .about-pictures{
    height: 3.5vw;
  }

  .sez2 {
    background-color: red;
  }
  
  .sez3 {
    background-color: pink;
  }

  .about-first-paragraph{
    margin-top: 3em;
  }
  .about-second-paragraph{
    margin-top: 1.5em;
  }
  .about-third-paragraph{
    margin-top: 1.5em;
  }


  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .about-main-div{
      padding: 0;      
      text-align: left;
      justify-content: center;
      display: flex;
      flex-direction: column; /* Stacks the children vertically on small screens */
      background-position: calc(100% + 20vw) calc(100% + 50vw); /* Align the image to the right and 50% below the div */
      background-size: auto 65%; /* Double the height of the background image */
        }
    .about-description-div {      
      background-position: calc(100% + 110vw) calc(100% - 200vw ); /* Align the image to the right and 50% below the div */
      background-size: auto 40% !important; /* Double the height of the background image */
      background-color: #d8e6f3;
    }
    .about-description-text, .sez{
      border-radius: 5vw !important;
    }
    .about-main-div h1{
      margin-left: -12vw;
      text-align: left;
      display: inline-block !important;
      margin-top: 5vw;
      font-size: 9vw;
    }
    .about-main-div p{
      margin-right: 1vw;
      margin-left: 3vw;
      text-align: left !important;
      font-size: 3.2vw;

    }
    .about-picture-div{
      margin: 0;
    }
    .about-picture-div img{
      width: 41vw;
    }
    .about-wrapper{
      padding-top: 2vw;
      gap: 3vw;
      flex-direction: column;
        
  } 
  .sez{
      
      width: 100%;
      align-items: center;
      align-content: center;
  }
  .overwrite-style{
    width: 100% !important;
  }
    .about-button{
      margin-top: 5vw;
      margin-bottom: 5vw;
      width: 95% !important;
      border-radius: 6vw;
      justify-content: center;
      align-self: center !important;
      
    }
    .about-description-div{
      flex-direction: column !important;
      align-content: center !important;
        }
    .about-description-div h2{
      margin-top: 5vw;
      font-size: 6vw;   
      margin-right: 35vw; 
    }
    .about-description-text{
      margin-top: 35vw !important;
      font-size: 3.4vw;
      margin-left: 10vw !important;
      white-space: normal;
      margin-right: 10vw !important;
        }
    .important-div p{
      margin-left: 0 !important;
      text-align: left !important;
      font-size: 3.4vw;

    }

    .sez{
      height: 27vw;
    }
    .sez h5{
      font-size: 5vw;
    }
    .sez p{
      font-size: 3vw;
    }
    .about-pictures{
      margin-left: 3vw;
      margin-right: 3vw;
      height: 10vw;
    }
    .about-text-div{
      display: flex;
      flex-direction: column; /* Stacks the children vertically on small screens */
      padding: 0;
      align-items: center;
      margin-left: 0;
    }
    .important-div h2{
      text-align: left !important;
      font-size: 9vw;
      padding-top: 5vw;
      line-height: 100%;

    }
    .important-div p{
      white-space: normal !important;
    }
    .important-div{
      padding-left: 13%;
      padding-right: 13% !important;
    }
    }