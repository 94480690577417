/*
OVO TI RESAVA SVE ZIVOTNE PROBLEME  
   margin-left: 10%;
    margin-right: 10%; 
    transform: translateY(-50%);
    OVO TI RESAVA SVE ZIVOTNE PROBLEME
    */

    @import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

    .bodyHome{
        
        white-space: normal !important;
        font-family: "Instrument Sans", sans-serif;
        margin: 0 auto; /* Centers the content horizontally */
        overflow-x: hidden; /* Prevent horizontal scroll */
        width: 100%; /* Ensures it takes the full available width */
    }
    .jumbotron {
        padding: 2rem 1rem;
        background-color: #e9ecef;
        border-radius: 10rem;
        color: white;
        background-size: cover;
        background-position: center;
    }
    .jumbo-paragraph{
        margin-top: 35px;
        font-weight: 400;
        font-size: 1.5vw !important;
        color: #0b1a51 !important;
        margin-bottom: 0;
    }
    .fixed-top{
        z-index: 5000 !important;
    
    }
    .navbar{
        border-bottom-left-radius: 8vw;
        border-bottom-right-radius: 8vw;
        padding-top: 2rem;
    }
    .navbar-brand{
        margin-left: 12%;
    }
    
    .navbar-brand img{
        width: 7vw;
    }
    .navbar-toggler{
        margin-right: 2vw;
        border: none !important;
    }
    .navbar-toggler-icon{
        
        color: white;
    }
    .offcanvas-body, .offcanvas-header{    
        border-top: none !important; /* Ensures no line appears at the top of the body */
        border-bottom: none !important; /* Removes the border line */
        box-shadow: none !important; /* Removes any shadow that might be present */
        padding: 0 !important;
        background-color: #0b1a51;
    }
    .offcanvas-header{
        transform: translateY(1vw);
        padding-bottom: 2vw !important;
    }
    .offcanvas{
        background-color: #0b1a51 !important;
    }
    .btn-close.btn-close-white {
        
        opacity: 1 !important; /* Ensure full opacity */
    }
    .btn-close.btn-close-white,
    .btn-close.btn-close-white:hover,
    .btn-close.btn-close-white:focus,
    .btn-close.btn-close-white:active {
        margin-top: 3vw !important;
        margin-right: 3vw !important;
        color: #fff !important; /* Ensuring it's white */
        filter: none !important; /* Removing any default filter that might be dimming the color */
        width: 4vw; /* Example width, set as needed */
        height: 4vw; /* Adjust height to maintain aspect ratio if necessary */
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>') !important; /* Custom SVG icon for clarity */
        
        background-size: 100%; /* Adjust the background size to keep the 'X' visually balanced */
    }
    .btn-close.btn-close-white {
        box-shadow: none !important;
        border: none !important;
    }
    .navbar-nav{
        margin-bottom: 1vw;
        margin-right: 13%;
        gap: 3vw;
        float: right;
        font-size: 1.1vw;
        font-weight: 450;
    }
    .nav-item {
        color: #353535 !important;
        text-decoration: none;
    }
    .nav-lang-mob{
        justify-content: center;
        text-align: center !important;
        color: white !important;
    }
    .pipe-color {
        color: #00ce7c; /* Replace #ff0000 with the color you want */
        display: inline-block;
        transform: scale(1.5,0.8);
        
        margin: 0 5px; /* Optional: Add margin to give some space around the character */
    
        
    }
    .content-container {
        border-radius: 190px !important;    
        margin-left: 12%;
        margin-right: 12%;
        z-index: 10; /* Ensures content-container stays above the video */
        position: relative;
        background-color: white;
    }
    
    .main-content {
        border-radius: 8vw !important;            
        z-index: 5; /* Ensures content-container stays above the video */
        background-color: white !important;
        position: relative;    
        margin-top: 10vw;
        width: 100%;
    }
    
    
    .main-content p {
        font-optical-sizing: auto;    
        color: #0b1a51;
        white-space: pre;
        line-height: 1.1;
    }
    
    .main-content h1 {
        line-height: 0.9;
        font-optical-sizing: auto;
        font-weight: 500;
        color: #0b1a51;
        white-space: pre;
        font-size: 5.5vw;
    }
    .button-jumbotron {   
        margin-bottom: 15px; 
        margin-left: 25px;    
        font-weight: 600;
        font-size: 1.3vw;
        vertical-align: middle; /* Align the button vertically within the line */
        padding: 0.9vw 2.5vw; /* Button padding */
        border-radius: 3rem; /* Rounded corners */
        text-decoration: none; /* No underline */
        background-color: white; /* White background */
        color: #353535; /* Black text color */
        border: 1px solid rgba(0, 0, 0, 0.2); /* Lighter black border */
        cursor: pointer; /* Cursor to pointer to indicate it's clickable */
        transition: background-color 0.5s, color 0.5s; /* Transition for smooth color change */
      }
      
      .modal-div{
        z-index: 15000 !important;
      }
    
      .offcanvas {
        width: 100%!important; /* Important to override inline styles if any */
    }
    
    /* Ensure offcanvas is full screen */
    .offcanvas.show {
        visibility: visible!important; /* Override any other visibility settings */
    }
      /* Hover state of the button */
      .button-jumbotron:hover {
        border: 0px;
        background-color: #00ce7c; /* Green background on hover */
        color: white; /* White text on hover */
      }
    .btn-outline {
        border: 2px solid green;
        background: none;
        color: green;
        padding: 10px 20px;
        border-radius: 20px;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
    }
    
    .side-content {
        width: 35%;
    }
    
    .side-content img {
        width: 100%;
        border-radius: 20px;
        margin-bottom: 20px;
    }
    
    .side-content p {
        color: #666;
    }
    
    .jumbo-main{
        margin-left: 12%;
        margin-right: 12% !important;
    }
    .btn-primary {
        background-color: #00ce7c;
        color: white;
        padding: 10px 20px;
        border-radius: 20px;
        border: none;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
    }
    
    .box-container {
        padding-bottom: 2vw;
        margin-left: 12%;
        margin-right: 12%;
        margin-top: 6vw;
        white-space:nowrap;    
    }
    .box {    
        vertical-align: top; 
        width: 32.5%;
        margin-right: 1%;
        height: 15vw;
        background-color: green;
        display:inline-block;
        border-radius: 2.5vw;
        
    }
    .first-box{
        position: relative;
        background-image: url('./img/TickPrevention.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-size: cover !important;
        background-position: center center;
        transition: background-size 0.5s ease, background-position 0.5s ease; /* Animate size and position */
        box-shadow: inset 0 -30px 60px -10px rgba(0,0,0,0.9);
    
    }
    .first-box:hover{
        background-size: 120% !important; /* Enlarge the background image */
        background-position: left bottom;
    }
    .second-box {    
        background-color: #00ce7c; /* Green background */
        position: relative; /* Establish a positioning context */
    }
    
    .button-slide {
        all: unset; /* Removes all inherited and browser default styling */
        display: block; /* Reapply display as needed */
        position: absolute;
        bottom: 1;
        margin-top: 2%;
        left: 50%;
        transform: translateX(-50%);
        padding: 4% 15%;
        font-size: 1.3vw;
        transition: background-color 0.5s, color 0.5s; /* Transition for smooth color change */
        border-radius: 3rem;
        font-weight: 600;
        box-sizing: border-box;
        cursor: pointer;
        background-color: #fff; /* Set background as needed */
        color: #353535; /* Set text color as needed */
        border: none; /* Adjust as needed */
    }
    .button-slide:hover{
        background-color: #0b1a51; /* Green background on hover */
        max-width: 100% ;    
    }
    .second-box-text{  
        margin-top: 10% !important;  
        margin-left: 13% !important;
        font-size: 1.6vw !important;
        color: white !important; 
    
    }
    .arrow-div{
        width: 3.5vw;
        position: absolute;
        margin: 7%;
        right: 0;
        top: 0;
    }
    .first-box-text {
        letter-spacing: 0.5px;
        font-weight: 100;
        line-height: 1 !important;
        position: absolute;
        bottom: 0;    
        margin-left: 10% !important;
        font-size: 1.3vw !important;
        margin-bottom: 1.5vw;
        color: white !important; 
    }
    
    
    .third-box {
        position: relative;
        background-image: url('./img/TickDetectCover.png');
        background-size: cover !important;
        background-repeat: no-repeat;
        background-size: 200%;
        background-position: center center;
        height: 40vw;
        position: relative; /* Ensure positioning context */
        top: -25vw; /* Position from the top */
        border-radius: 2.5vw;
        margin-bottom: -300px;
        margin-right: 0;
    
    }
    
    .third-box-inner-box{
        position: absolute;
        width: 100%;
        bottom: 0;
        backdrop-filter: blur(10px); /* Adjust blur radius as needed */
        box-shadow: inset -180px 5px 25px -15px rgba(255, 255, 255, 0.4);
        transition: background-color 0.3s, color 0.3s;
        background-color: rgba(114, 114, 114, 0.2); /* Adjust the opacity and color as needed */
    
    }
    
    .third-box-inner-box:hover {
        position: absolute;
        bottom: 0;
        backdrop-filter: blur(10px); /* Adjust blur radius as needed */
        box-shadow: inset -180px 5px 25px -15px rgba(0, 206, 124, 0.4);
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.3); /* Adjust the opacity and color as needed */
    }
    .third-box-inner-box:hover .second-box-second-text {
        visibility: visible;    
    }
    .second-box-second-text{
        visibility: hidden;
        position: absolute;
        color: white !important;
        margin-left: 13% !important;
        margin-bottom: 5% !important;
        font-size: 1.3vw !important;
        letter-spacing: 0.5px;
        font-weight: 50 !important;
        bottom: 0;
    }
    
    .video-container {
    
        top: -150px;
        z-index: 1;
        width: 100%; /* Adjusts to the width of the container, or set a max-width if necessary */
        height: 70em; /* Set a fixed height or as per your design requirement */
        overflow: hidden; /* Hides parts of the video that overflow the container size */
        position: relative; /* Needed for absolute positioning of the video */
        
    }
    
    .video-container video {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensures the video covers the area of the container */
        object-position: center; /* Centers the video within the container */
    }
    .box-overlay {
        padding: 1%;
        margin-left: 12%;
        margin-right: 12%;
        position: absolute; /* Position it over the video */
        border-radius: 2vw;
        left: 0;
        right: 0;
        bottom: 0; /* Align to the bottom */
        margin-bottom: 200px;
        backdrop-filter: blur(12px); /* Create the blur effect */
        display: flex; /* For horizontal alignment of boxes */
        align-items: center;
        justify-content: space-around; /* Add space around the boxes */
        box-sizing: border-box; /* Make sure padding doesn't affect overall width */
        background-color: rgba(255, 255, 255, 0.2); /* Adjust the opacity and color as needed */
    } 
    
    /* Styles for your individual boxes */
    .video-box {
        margin-top: 1vw;
        margin-bottom: 1vw;
        white-space: pre-wrap;
        justify-content: start; /* Aligns children (icon and text) at the start */
        padding: 2vw; /* Adds some padding within the boxes */    
        box-sizing: border-box;
        width: 31%;
        height: 10vw;
        color: white;
        background-color: rgba(255, 255, 255, 0.5); /* Adjust the opacity and color as needed */
        border: 1px solid rgba(255, 255, 255, 0.651); /* Lighter black border */
        background-color: rgba(255, 255, 255, 0.0);;
        border-radius: 2vw;
    }
    .video-box-content {    
    
        display: flex;
        width: 100%;
        align-items: center; /* Aligns the SVG and text vertically */
        flex-direction: row; /* arrange items horizontally */
        align-items: center; /* vertically center items */
        justify-content: center; /* horizontally center items */
    }
    
    .video-box-icon {
        
        /* Adjust the size of the SVG container if needed */
        flex-shrink: 0; /* Prevents the icon from shrinking */
    }
    
    .video-box-title {
        
        font-size: 1.3vw;
        overflow: hidden;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 10px !important;
        margin: 0; /* Removes default margin from the paragraph */
        /* Additional styling for the title */
    }
    
    .video-box-description {
        margin-bottom: 10% !important;
        line-height: 110% !important;
        font-size: 0.8vw;
        font-weight: 300 !important;
    
    }
    
    .video-box-image {    
        margin-right: 2vw;
        height: 4vw;
    }
    
    .map-container{
        overflow: hidden;
        flex-direction: column;
        z-index: 5; /* Ensures content-container stays above the video */
        background-color: #d8e6f3;
        position: relative;
        justify-content: center;
        align-items: center;    
        border-radius: 8vw !important;            
        top: -290px;
        width: 100%; 
        
    }
    .map-app-description{
        margin-left: 12%;
        margin-right: 12%;
        display: flex;
        align-items: center;
    }
    .phone-container{
        margin-top: 8em;
        border-radius: 36px; /* Rounded corners of the phone */
    }
    .phone-image{
        display: block;
        width: 20vw; /* Adjust the width as needed */
        height: 100%; /* Adjust the height as needed */
          -webkit-filter: drop-shadow(-35px -35px 0px #00ce7c);
      filter: drop-shadow(-35px -35px 0px #00ce7c);
    }
    
    .application-text-content{
        
        margin-top: 9em;
        margin-left: 13em;    
        white-space: pre-wrap;
        color:#0b1a51;
    }
    .application-text-content p{    
        margin-bottom: 50px;
        font-size: 1.3vw;
        font-weight: 300;
        margin-left: 2vw !important;
    }
    .application-text-content h2{
        font-size: 2vw;
        margin-bottom: 35px;
        line-height: 100%;
        font-weight: 500;
    }
    .application-button a{
        white-space: nowrap !important;
        text-wrap: nowrap !important;
    }
    .application-button{
        margin-top: 1em;
        margin-left: 30px;    
        font-weight: 600;
        font-size: 1.3vw;
        vertical-align: middle; /* Align the button vertically within the line */
        padding: 0.9rem 3.2rem; /* Button padding */
        border-radius: 2.5vw;
        text-decoration: none; /* No underline */
        background-color: white; /* White background */
        color: #353535; /* Black text color */
        border: 0px solid rgba(0, 0, 0, 0.2); /* Lighter black border */
        cursor: pointer; /* Cursor to pointer to indicate it's clickable */
        transition: background-color 0.5s, color 0.5s; /* Transition for smooth color change */
    }
    
    
      /* Hover state of the button */
      .application-button:hover {
        border: 0px;
        background-color: #00ce7c; /* Green background on hover */
        color: white; /* White text on hover */
      }
    
    
    
    .preventive-div{
        white-space: pre-wrap;
        margin-top: 10em;
        margin-left: 12%;
        margin-right: 12%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white; /* White text color */
        padding: 2em; /* Padding around the content */
        padding-top: 0.4vw;
        padding-bottom: 0.4vw;
        border-radius: 2.5vw;
        background-color: #0b1a51;
        margin-bottom: 25em;    
    }
    
    .preventive-text-content{
        
        margin: 5%;
    }
    
    .preventive-text-content h2{
        font-size: 2vw;
        margin-bottom: 5%;
    }
    
    .preventive-text-content p {
        font-weight: 400;
        font-size: 1.3vw;    
        line-height: 110%;
    
    }
    
    .preventive-button{
        font-weight: 300;
        margin-right: 5%;
        font-size: 1.3vw;
        margin-right: 5em;
        vertical-align: middle; /* Align the button vertically within the line */
        padding: 1vw 5vw; /* Button padding */
        border-radius: 3rem; /* Rounded corners */
        text-decoration: none; /* No underline */
        background-color: #00ce7c; /* White background */
        color: white; /* Black text color */
        border: 0px solid rgba(0, 0, 0, 0.2); /* Lighter black border */
        transition: background-color 0.5s, color 0.5s; /* Transition for smooth color change */
    
        cursor: pointer; /* Cursor to pointer to indicate it's clickable */
    }
    
    .preventive-button:hover{
        overflow: hidden;
        color:black;
        background-color: white;
    }
    
    
    .connect-div{
        top: -550px;
        position: relative; 
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        width: 100%;
        background-color: white;
        border-radius: 8vw !important;            
        z-index: 5;    
    
    }
    .connect-title-div{
        margin-bottom: 8%;
        padding-top: 5em;
        white-space: pre-wrap;
        text-align: center;
        color:#0b1a51;
    }
    .connect-title-div h2{
        margin-top: 40px;
        font-size: 6vw;
    }
    .connect-title-div p{
        margin-top: 10px;
        line-height: 120%;
        font-size: 1.3vw;
    }
    
    .connect-question-div{
        padding-bottom: 6em;
        margin-top: 20em !important;
        padding-top: 2em;
        display: flex;
        background-color: #00ce7c;
        margin-left: 12%;
        margin-right: 12%;
        border-radius: 2.5vw;
        margin-bottom: -15em;    
    }
    
    .connect-question-text-div{
        color: white;
        white-space: pre-wrap;
        margin: 5% 7%;
    }
    
    .connect-question-text-div h3{
        line-height: 90%;
        font-size: 5vw;
        margin-bottom: 2vw;
    }
    
    .connect-question-text-div p{
        white-space: pre;
        font-size: 1.3vw;    
        line-height: 130%;
    }
    .connect-question-form-div{
        margin-top: 3%;
        gap: 2vw;
        margin-left: 10%;
        margin-right: 8%;
        width: 100%;
        display: flex; /* This enables flexbox */
        flex-direction: column; /* This stacks children vertically */
        justify-content: center;
    }
    .question-button{
        margin-top: 35px;
        text-wrap: nowrap;
        align-self: flex-end;
        flex-grow: 0;
        width: fit-content;    
        font-weight: 600;
        font-size: 1.3vw;
        padding: 0.8rem 3.5rem; /* Button padding */
        border-radius: 3rem; /* Rounded corners */
        text-decoration: none; /* No underline */
        background-color: white; /* White background */
        color: #353535; /* Black text color */
        border: 0px solid rgba(0, 0, 0, 0.2); /* Lighter black border */
        cursor: pointer; /* Cursor to pointer to indicate it's clickable */
        transition: background-color 0.5s, color 0.5s; /* Transition for smooth color change */
    }
    
    .question-button:hover{
        color: white;
        overflow: hidden;
        background-color: #002366;
    }
    .line-input {
        margin-top: 1vw;
        background-color: transparent; /* No background color */
        border: none; /* No borders on sides and top */
        border-bottom: 0.5px solid #30e29b; /* Green bottom border, 2px thick */
        outline: none; /* Hide the outline when focused */
        padding: 0.5vw 0; /* Padding on top and bottom to space text from border */
        font-size: 1.3vw; /* Font size of input text */
        color: white; /* Color of input text */
    
      }
    
      .line-input::placeholder {    
        color: white; /* Light grey color for placeholder */
      }
      .message-input{
        resize: none;
        padding-bottom: 5px;
        padding-top: 70px;
      }
    
      .footer-container {
        
        background-color: #0b1a51;
        color: white;
        font-size: 1.1em; /* Adjust as necessary */
        padding: 45px 12%;
      }
      
      .footer-upper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid gray; /* Gray line below the content */
        padding-bottom: 40px; /* Spacing between content and line */
    
      }
      .sez{
        overflow: unset;
      }
      .nav-inst-logo img{
        height: 12vw !important;
      }
      .contact-item {
        display: flex;
        align-items: center;
      }
    
      .nav-mob-header{ 
        flex-direction: column;
        border: 0px !important;
      }
      .nav-mob{
        font-size: 5vw !important;
        margin-bottom: 2vw;
      }
      .nav-mob img{
        height: 5vw;
      }
      .nav-mob p{
        font-size: 5vw !important;
      }
      .icon {
        margin-bottom: 15px;
        height: 1.5vw;
        margin-right: 20px; /* Space between icon and text */
      }
      
      .footer-logo img
     {
        height: 4vw; /* Adjust as necessary */
      }
      .footer-institute-logo img {
        height: 4.5vw;
      }
      .footer-shortcut{
        color:#677093;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
      }
      .navbar-toggler-icon {
        background-image: none !important; /* Remove default background image */
    }
    
    .navbar-toggler-icon::before {
        
        content: '\2630'; /* Unicode for hamburger icon */
        font-size: 5vw; /* Increase size of the icon */
        color: #0b1a51; /* Set color of the icon */
        text-shadow: 0 0 2 #0b1a51; /* Optional: adding stroke, adjust color as needed */
    }
    
    .burger{
        transform: scaleY(180%);
    
        width: 5vw;
        
    }
      .canvas-off{
        margin-top: 5vw;
        margin-bottom: 4vw;
        width: 90%;
        height: 1px;
        background-color: #545e85;
      }
      .footer-contact p,
      .footer-links a {
        font-size: 1.1vw;
        display: flex;
        align-items: center;
        color: white;
        text-decoration: none;
      }
      
      .footer-links a {
        font-size: 1.1vw;
    
        margin-bottom: 0.5em; /* Spacing between links */
      }
      
      .footer-links a:hover {
        text-decoration: underline;
      }
    
    
      .footer-institute-logo {
        display: flex;
        align-items: center;
      }
    
      .footer-lower {
        color:#677093;
        font-size: 1.1vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px; /* Spacing above the copyright text */
      }
      
      /* Responsive adjustments if necessary */
     
      .statistics-map-container {
        white-space: pre-wrap;
        color: #002366;
        border-radius: 2.5vw;
        background-color: white;
        margin-left: 12%;
        margin-right: 12%;
        display: flex;
        align-items: flex-start;
        padding-left: 3em;
        padding-top: 4em;
        margin-top: 9em;
        
    }
    .statistics-container{
        margin-left: 5vw;
    }
    .statistics-container h2 {
        padding-right: 15vw;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 6vw;
    }
    
    .statistics-container p{    
        font-weight: 450;
        font-size: 1.3vw;
    }
    .user-statistics h2{
        color: #00ce7c;
    }
    .first-text-mob{
        margin-bottom: 4vw;
        margin-right: 2vw;
        font-size: 4vw !important;
    }
    .second-mob-text{
        white-space: normal;
        font-size: 6vw !important;
        line-height: 120%;
        margin-top: 5vw !important;
        margin-bottom: 0vw;
        margin-right: 25vw !important;
    }
    .second-button-mob{
        margin-top: 5vw !important;
        margin-bottom: 10vw !important;
        white-space: nowrap;
        text-align: center;
        padding-bottom: 4vw !important;
        padding-top: 4vw !important;
        width: 80% !important;
        font-size: 4vw !important;
        font-weight: 550 !important;
    
    }
    .mob-first{
        margin-top: 5%;
        border-radius: 6vw;
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
        height: 55vw;
    }
    .mob-slide-pic{
        border-bottom-left-radius: 8vw;
        border-bottom-right-radius: 8vw;
        flex-direction: column;
        align-content: center;
        transform: translateY(-80vw);
        height: 200vw;
        background-color: white;
        color: black;
    }
    .pargraph-mob-a{
        text-decoration: none;
    }
    .paragraph-mob{
        text-decoration: underline;
        margin-bottom: 10vw !important;
        color:#0b1a51;
        margin-top: 6vw;
        text-align: center;
    }
    .third-text-mob{
        white-space: pre-wrap;
        color:#0b1a51;
        text-align: center;
        margin-top: 15vw;
        margin-left: 5%;
        margin-right: 5%;
        font-size: 9vw;
    }
    .semaphore-container{
        margin-top: 4vw;
    }
    .semaphore-container h3 {
        font-weight: 400;
        font-size: 2vw;
        margin-bottom: 0.5em;
    }
    .mobile-video{
        transform: translateX(2.5vw);
        white-space: nowrap;
        font-weight: 550 !important;
        font-size: 4vw !important;
        
        padding-top: 4vw !important;
        margin-top: 2vw;
        padding-bottom: 4vw !important;
        width: 90% !important;
    }
    .legend-text{
        font-weight: 400;
        line-height: 100%;
        margin-right: -5vw;
        font-size: 1.3vw;
    }
    .semaphore-level {
        display: flex;
        align-items: center;
        margin-bottom: 1vw;
    }
    .instruction-text{
        white-space: normal;
        font-weight: 400;
        margin-top: 2em;
        margin-right: -7vw;
        margin-bottom: 3em;
    }
    .icon-background {
        
        margin-right: 1.5em; /* Spacing between icon and text */
    }
    
    /* Color coding for semaphore levels */
    
    .map-placeholder {
    margin-left: 10vw;    
    }
    
    .map-image{    
        height: 2.5vw;
    }
    
    .no-scroll {
        overflow: hidden;
      }
    .third-box-mob{
        z-index: -5;
        top: 0;
        width: 100%;
        height: 250vw;
        transform: translateY(-120vw);
        position: relative;
    
    }
    .inner-box-mob{
        
        padding-bottom: 150px;
        position: absolute !important;
        border-radius: 0;
        padding-top: 2vw;
        border-top-left-radius: 8vw;
        border-top-right-radius: 8vw;    
        height: fit-content;
        
    }
    
    .mob-wrapper{
        margin-bottom: 3vw;
        margin-left: 5%;
        display: flex;
    }
    .mob-wrapper img{
        width: 13vw;
    }
    .mob-box-text{
        color: white;
        margin-left: 5vw;
        margin-right: 15vw;
    }
    
    .visib{
        transform: translateY(20vw);
        margin-bottom: 0px !important;
        visibility: hidden;
    }
    .mob-title{
        margin-bottom: 1vw;
        font-size: 5vw;
    }
    .mob-description{
        line-height: 100%;
        font-size: 4vw;
    }
    @media only screen and (min-device-width : 400px) and (max-device-width : 450px) {
        .inner-box-mob{    
            padding-bottom: 115px;
        }
    }
    @media only screen and (min-device-width : 350px) and (max-device-width : 400px) {
        .inner-box-mob{    
            padding-bottom: 165px;
        }
    }
    
    @media only screen and (min-device-width : 320px) and (max-device-width : 350px) {
        .inner-box-mob{    
            padding-bottom: 150px;
        }
    }
    
      @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
        .nav-mobile{     
            display: flex ;   
            flex-direction: column;
            text-align: center !important;
            margin-top: 15vw !important;
            margin-bottom: 15vw !important;
            font-size: 5vw !important;
    
        }
        .nav-brand-mob{
            height: 12vw !important;
        }
        .nav-mobile a{        
            flex-direction: column;
            text-align: center !important;
            margin-left: 0 !important;
            font-size: 5vw !important;
        }
        .sec-paragr{
            margin-right: 0;
        }
        .icon-third-paragraph{
            margin-bottom: 2vw;
        }
        .preventive-div::before {
            content: '';
            position: absolute;
            top: -50vw; /* Extend upwards by the margin amount */
            left: -50vw; /* Extend leftwards */
            right: -50vw; /* Extend rightwards */
            bottom: -50vw; /* Extend downwards */
            background-color: white; /* This simulates the margin color */
            z-index: -1;
          }
          .map-container{
            border-radius: 0 !important;
          }
          .navbar-brand{
            margin-top: 1vw;
            margin-bottom: 2vw;
            margin-left: 3vw;
            margin-right: 0;
    
          }
          .navbar-brand img{
            width: 20vw;
          }
          .line-input{
            border-bottom: 2px solid #75f1c0; /* Green bottom border, 2px thick */

          }
          .footer-upper,.footer-lower{
            justify-content: center;        
            flex-direction: column;
            align-content: center;
            text-align: center;
            font-size: 4vw !important;
    
          }
          .phone-image{
            display: block;
            width: 50vw; /* Adjust the width as needed */
            height: 100%; /* Adjust the height as needed */
              -webkit-filter: drop-shadow(-25px -25px 0px #00ce7c);
          filter: drop-shadow(-25px -25px 0px #00ce7c);
        }
      
          .footer-logo img
          {
            margin-top: 3vw;
            margin-bottom: 3vw;
             height: 10vw; /* Adjust as necessary */
           }
           .footer-institute-logo img {
             height: 10.5vw;
             margin-bottom: 1vw;
           }
           .footer-links{
            margin-bottom: 3vw;
           }
          .footer-links a, .footer-container p{
            margin-right: 0 !important;
            padding-right: 0;
            font-size: 3.4vw;        
          }
          .footer-logo,
          .footer-institute-logo,
          .footer-lower {
            margin-bottom: 1em; /* Spacing between elements when stacked */
          }   
          .icon{
            
            height: 3vw;
          }
          .map-app-description{
            position: relative; /* Needed if z-index is used */
            z-index: 1;
                    flex-direction: column;
            text-align: left;
          }
          .application-text-content, .application-button{
            margin: 0;
          }
          .application-text-box h2, .application-text-box p{
            margin-top: 4vw;
            margin-bottom: 0;
          }
          .application-text-box p{
            margin-left: 6vw !important;
            font-size: 3.4vw;
            margin-bottom: 6vw;
          }
          .application-text-box h2{
            margin-right: -5vw;
            font-size: 6vw;
          }
          .application-button {    
            
            border-radius: 8vw;
            margin-left: 2vw;
            margin-right: 2vw;
            white-space: nowrap;
            padding-right: 20vw;
            padding-left: 20vw;
            padding-bottom: 4vw !important;
            padding-top: 4vw !important;
            width: 100% !important;
            font-size: 4vw !important;
            font-weight: 550 !important;
        
          }
          .content-with-svg{
            margin-top: 10vw;
            
          }
          .third{
            z-index: -1;
            height: 250vw;
            margin-top: -80vw;
            position: relative;
            background-image: url('./img/TickDetectCover.png');
            background-size: cover !important;
            background-repeat: no-repeat;
            background-size: 200%;
            background-position: center center;
            width: 100% !important;
          }
          .contact-link{
            
            margin-left: 12%;
            text-align: center !important;
          }
          .contact-phone{
            margin-left: 5vw;
            padding-bottom: 3vw;
        }
        .connect-title-div h2{
            font-size: 9vw;
            margin-top: 0;
            margin-bottom: 5vw !important;
        }
        .connect-title-div p{
            white-space: normal;
            margin-right: 4vw;
            margin-left: 4vw;
            margin-top: 3vw;
            font-size: 3.4vw;
        }
        .connect-title-div{
            padding-top: 0;
        }
        .phone-image{
            margin-top: -10vw
        }
        .preventive-div{
            position: relative; /* This sets the positioning context for the pseudo-element */
            padding-top: 5vw;
            
            margin-top: 10vw;
            justify-content: center;
            margin-left: 5%;
            margin-right: 5%;
            flex-direction: column;
            align-content: center;
            border-radius: 6vw;
            text-align: center;
            padding-bottom: 10vw;
        }
        
    
        .video-mob-text{
            text-align: left;
            color: white;
            margin-left: 5%;        
            transform: translateY(-90vw);
        }
        .video-mob-text p {
            margin-bottom: 1vw;
            margin-right: 10vw;
            line-height: 5vw;
            font-size: 4vw;
        }
        .video-mob-text h3 {
            margin-bottom: 1vw;
            font-size: 8vw;
        }    
    
        
        .video-mob{
           /* height: 200vw;
            transform: translateX(-110vw) translateY(-80vw) scale(35%); */
            height: 200vw;
            transform: translateX(-100vw) translateY(-50vw) scale(80%);
        }
        .preventive-div h2{
            font-size: 9.2vw;
        }
        .preventive-div p{
            white-space: normal !important;
            font-size: 3.4vw;
        }
        .preventive-button{
            margin: 0;
            padding-bottom: 4vw !important;
            padding-top: 4vw !important;
            width: 90% !important;
            font-size: 4vw !important;
            font-weight: 550 !important;
        
        }
        .statistics-map-container{
            padding-top: 5vw !important;
            margin-top: 20vw;
            border-radius: 8vw !important;            
            margin-left: 0;        
            margin-right: 0;
            padding: 0 ;
            justify-content: center;
            display: flex;
            flex-direction: column !important;
            align-content: center;
            position: relative; /* Needed if z-index is used */
    
        }
    
        .map {
            transform: translateX(-7vw);     
            width: 290vw !important; /* Example: setting the width to 80% of the viewport width */
        }
        .instruction-text{
            margin-top: 0;
            margin-left: 10%;
            margin-right: 10%;
            line-height: 100%;
            white-space: normal;
            font-size: 5vw !important;
            margin-bottom: 10vw;
        }
        .statistics-container{
            margin-top: 5vw !important;
            margin: 0;
            width: 100%;
            
            justify-content: center;
            flex-direction: column;
            align-content: center;    
        }
        .statistics-container h2{
            width: 100%;
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 20vw;
        }
        .statistics-container p{
            font-size: 3.4vw;
            text-align: center;
        }
        .legend-text{
            font-size: 3.4vw;
        }
        .semaphore-container{
            margin-top: -22vw;
        }
        .semaphore-container h3{
            margin-bottom: 0;
            transform: translateY(200vw);
            text-align: center;
            font-size: 7vw;
            font-weight: 500;
        }
        .semaphore-level{
            margin-top: 1vw;
            margin-bottom: 2vw;
            align-self: center;
            margin-left: 24vw;
            transform: translateY(205vw);
        }
        .map-placeholder {
            margin-bottom: 50vw;
        }
        .foot-main{
            padding-top: -15vw !important;
            margin-top: -35vw !important;
        }
        .map-image{
            height: 6vw !important;
        }
        .main-div{
            margin-left: 5% !important;
            margin-right: 5% !important;
            transform: translateY(4vw);
            display: grid;
            grid-template-columns: repeat(2, 1fr) !important;
            row-gap: 3% !important;
            column-gap: 3%;
            
        }
        .visib{
            margin-top: 0 !important;
            
            visibility: visible;
        }
    
        .nav-font{
            
            font-size: 5vw !important;
        }
        .main-connection{
            padding-top: 1vw;
            
            margin-top: 0p !important;
            transform: translateY(30vw);
            margin-bottom: -80vw;
        }
      }
    
    
    
      /* Default styles ensuring the navbar is expanded */
    
      .footer-nav-upper{
        justify-content: center;
    
        flex-direction: column;
        align-content: center;
        text-align: center;
        font-size: 3vw !important;
    
      }
    
      .footer-nav-logo img
      {
        margin-top: 3vw;
        margin-bottom: 3vw;
         height: 10vw; /* Adjust as necessary */
       }
    
       .footer-nav-links{
        margin-bottom: 3vw;
       }
      .footer-nav-links a, .footer-nav-links p{
        margin-right: 0 !important;
        text-align: center !important;
        margin-left: 0;
        justify-content: center;
        padding-right: 0;
        font-size: 3vw !important;        
      }
      .main-connection{
        margin-top: 0px !important;
      }
      .mob-main{
    
        }